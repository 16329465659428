export default {
  /* USE:
    // allow input only numbers
    v-remove-non-numeric-chars

    // allow input numbers and ONLY A '-' at leading position
    v-remove-non-numeric-chars.allow="'-'"

    // allow input numbers and MANY '-' ANYWHERE
    v-remove-non-numeric-chars.allow.anywhere="'-'"

    // allow input numbers and ONLY A '.' that is not leading and after a number
    v-remove-non-numeric-chars.allow="'\\.'"

    // allow input numbers and MANY '.' ANYWHERE
    v-remove-non-numeric-chars.allow.anywhere="'\\.'"

    // allow input numbers and ONLY A '-' and ONLY A '.'
    v-remove-non-numeric-chars.allow="'-\\.'"

    // allow input numbers and MANY '-' and MANY '.'
    v-remove-non-numeric-chars.allow.anywhere="'-\\.'"

    // allow input numbers and ONLY A '-' and ONLY A '.' and trim numbers after percent
    v-remove-non-numeric-chars.allow.trim-after-percent="'-\\.'"

    // allow input numbers and ONLY A '-' and ONLY A '.' and trim numbers after percent and not allow leading '00' or '-00'
    v-remove-non-numeric-chars.no-start-00.allow.trim-after-percent="'-\\.'"

    // allow input all number: 0-9, use in phoneNumber, identityCardNumber,...; allow 0 at leading position
    v-remove-non-numeric-chars.allNumber
  */
  bind: (el, binding) => {
    if (binding?.value === false) return
    let inputOrTextarea
    if (el.tagName === 'INPUT' || el.tagName === 'TEXTAREA') {
      inputOrTextarea = el
    } else {
      inputOrTextarea = el.querySelector('input')
      if (!inputOrTextarea) {
        inputOrTextarea = el.querySelector('textarea')
      }
    }
    if (inputOrTextarea) {
      if (binding.modifiers?.allNumber) {
        inputOrTextarea.setAttribute(
          'oninput',
          `this.value = this.value.replace(/[^0-9]+/g, '')${binding.modifiers.noSpace ? '.replace(/\\s+/g, \'\')' : ''};`,
        )
        return
      }
      const hasException = binding.modifiers?.allow && binding.value
      const exception = hasException ? binding.value : ''
      const ruleNoStart00 = binding.modifiers?.['no-start-00']
        ? ".replace(/(^-?)(0+)([0-9]+)/, '$1$3')"
        : ''
      const anywhere = binding.modifiers?.anywhere
      let otherRules = ''
      if (hasException) {
        let ruleMinus = ''
        let ruleDot = ''

        if (!anywhere) {
          // remove none-leading minus
          ruleMinus = binding.value.includes('-')
            ? ".replace(/([0-9-\\.]+)(-)/, '$1')"
            : ''
          // keep only a dot that is not leading and after a number
          ruleDot = binding.value.includes('.')
            ? ".replace(/^\\./, '').replace(/^-\\./, '-').replace(/(\\.)([0-9]*)(\\.)/, '$1$2')"
            : ''
        }

        // remove numbers after percent digit
        const ruleTrimAfterPercent = binding.modifiers?.['trim-after-percent']
          ? ".replace(/(\\.[0-9]{2})[0-9]+$/g, '$1')"
          : ''

        otherRules = `${ruleMinus}${ruleDot}${ruleTrimAfterPercent}`
      }

      const oninputAttribute = `this.value=this.value.replace(/[^0-9${exception}]+/g, '')${ruleNoStart00}${otherRules};`
      inputOrTextarea.setAttribute('oninput', oninputAttribute)

      // remove ending dot & remove endding zeros that after dot
      // examples: 123. => 123; 123.0 => 123; 123.00 => 123; 123.40 => 123.4
      inputOrTextarea.setAttribute(
        'onblur',
        "this.value = this.value ? Number(this.value) : ''",
      )
    }
  },
}
